<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>model_type </label>
          <input type="text" :class="errors.model_type?'form-control is-invalid':'form-control'"
                 v-model="form.model_type">

          <div class="invalid-feedback" v-if="errors.model_type">
            <template v-for=" error in errors.model_type"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>identifiants_sadge </label>
          <input type="text" :class="errors.identifiants_sadge?'form-control is-invalid':'form-control'"
                 v-model="form.identifiants_sadge">

          <div class="invalid-feedback" v-if="errors.identifiants_sadge">
            <template v-for=" error in errors.identifiants_sadge"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>permissions </label>
          <CustomSelect
              :key="form.permission"
              :url="`${axios.defaults.baseURL}/api/permissions-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.permission"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.permission_id=data.id;form.permission=data}"
          />
          <div class="invalid-feedback" v-if="errors.permission_id">
            <template v-for=" error in errors.permission_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateModel_has_permissions',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'permissionsData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        permission_id: "",

        model_type: "",

        model_id: "",

        extra_attributes: "",

        deleted_at: "",

        identifiants_sadge: "",

        creat_by: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/model_has_permissions', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        permission_id: "",
        model_type: "",
        model_id: "",
        extra_attributes: "",
        deleted_at: "",
        identifiants_sadge: "",
        creat_by: "",
      }
    }
  }
}
</script>
